<template>
    <div class="Polaris-FormLayout__Item">
        <div class="Polaris-Labelled__LabelWrapper">
            <div class="Polaris-Label"><label :for="id + 'input' " :id="id + 'label'" class="Polaris-Label__Text"
                    v-text="label"> </label></div>
        </div>
        <div class="Polaris-Connected">

            <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                <div class="Polaris-TextField " :class=" invalid ? 'Polaris-TextField--error' : '' ">

                    <input :id="id + 'input'" class="Polaris-TextField__Input" type="text"
                        :aria-labelledby="id + 'label'" :describedby="id + 'helpText'" aria-invalid="true"
                        aria-multiline="false" :value="value" @input="$emit('input', $event.target.value)">
                    <div class="Polaris-TextField__Backdrop"></div>
                </div>
            </div>
        </div>
        <div class="Polaris-Labelled__Error" v-if="invalid">
            <div class="Polaris-InlineError">
                <div class="Polaris-InlineError__Icon">
                    <span class="Polaris-Icon">
                        <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                            <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z"
                                fill-rule="evenodd"></path>
                        </svg>
                    </span>
                </div>
                {{ invalid }}
            </div>
        </div>
        <div class="Polaris-Labelled__HelpText" :id="id + 'helpText'" v-text="helpText" v-if="helpText"></div>
        <br>
        <div class="Polaris-TextContainer">
            <div class="Polaris-Stack">
                <div class="Polaris-Stack__Item"><span class="Polaris-Tag Polaris-Tag--removable"><span title="Antique"
                            class="Polaris-Tag__TagText">Antique</span><button type="button" aria-label="Remove Antique"
                            class="Polaris-Tag__Button"><span class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                    class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                    <path
                                        d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                                        fill-rule="evenodd"></path>
                                </svg></span></button></span></div>
                <div class="Polaris-Stack__Item"><span class="Polaris-Tag Polaris-Tag--removable">
                        <span title="Rustic" class="Polaris-Tag__TagText">Rustic</span>
                        <button type="button" aria-label="Remove Rustic" class="Polaris-Tag__Button">
                            <span class="Polaris-Icon">
                                <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                    <path
                                        d="M11.414 10l4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
                                        fill-rule="evenodd"></path>
                                </svg>
                            </span>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
            },
            label: {
                type: String,
            },
            helpText: {
                type: String,
            },
            invalid: {
                type: String,
                default: null,
            },
        },
        computed: {
            id() {
                return this._uid;
            }
        }
    }
</script>

<style>

</style>